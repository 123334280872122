import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MsgBannerService } from '../components/msg-banner/msg-banner.service';
import { RestErrorResponse } from './models/rest-error-response.model';
import {ApplicationInsightsService} from "../../telemetry.service";
import {SeverityLevel} from "@microsoft/applicationinsights-web";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    private msgBanner: MsgBannerService,
    private appInsights: ApplicationInsightsService
  ) {}

  handleError(errorResponse: any) {
    const router = this.injector.get(Router);
    console.log('URL: ' + router.url);
    if (errorResponse instanceof HttpErrorResponse) {
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      const error = errorResponse.error as RestErrorResponse;
      if (error?.warningMessages?.length > 0) {
        this.msgBanner.addMessages(
          error.warningMessages.map((warning) => warning.description)
        );
      }
      if (error?.errorMessages?.length > 0) {
        if (router.url === '/login' && error.status == 500) {
          this.msgBanner.addMessage(
            $localize`An authentication error has occured! Please verify your credentials and try again. ` +
            $localize`If you continue to have issues contact the Field Support Center!`);
        } else {
          if (error.errorMessages.some(message => message.description.includes('0006'))) {
            this.msgBanner.addMessage(
              $localize`An error has occurred. Please verify your entries and try again. <br> ` +  
              $localize`If you continue to have issues contact the Field Support Center.`
            );
          } else {
            this.msgBanner.addMessages(error.errorMessages.map(error => error.description));
          }
        }
      }
    } else {
      // THIS IS NEEDED FOR FRONTEND EXCEPTION (IN CONSOLE!!)
      // PLEASE DO NOT DELETE THIS
      this.appInsights.trackException({
        exception: errorResponse,
        severityLevel: SeverityLevel.Error,
      });

      throw errorResponse;
    }
  }
}
