import { Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import { AngularPlugin, ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import {
    ApplicationInsights,
    ICustomProperties,
    IDependencyTelemetry,
    IEventTelemetry,
    IExceptionTelemetry,
    IMetricTelemetry,
    IPageViewTelemetry,
    ITelemetryItem,
    ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import {filter} from "rxjs/operators";

@Injectable()
export class ApplicationInsightsService {

    myinjector = Injector.create({
        providers: [
            { provide: ApplicationinsightsAngularpluginErrorService, useClass: ApplicationinsightsAngularpluginErrorService }
        ]
    });

    private angularPlugin = new AngularPlugin(this.myinjector);
    private appInsights = new ApplicationInsights({
        config: {
            connectionString: environment.applicationInsightsConnectionString,
            extensions: [this.angularPlugin],
        },
    });

    constructor(private router: Router) {
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
        });
        // Subscribe to route changes to log how long a user spends on a page
        this.router.events
            .pipe(filter((event: unknown): event is ResolveEnd => event instanceof ResolveEnd))
            .subscribe((event: ResolveEnd) => {
                const activatedComponent = this.getActivatedComponent(event.state.root);
                if (activatedComponent)
                    this.appInsights.trackPageView({
                        name: activatedComponent.name,
                        uri: event.urlAfterRedirects,
                    });
            });
    }

    // expose tracking methods
    trackEvent(event: IEventTelemetry, customProperties?:ICustomProperties) {
        this.appInsights.trackEvent(event, customProperties);
    }

    setAuthenticatedUserContext(authenticatedUserId: string) {
        this.appInsights.setAuthenticatedUserContext(authenticatedUserId, undefined, true);
    }

    clearAuthenticatedUserContext() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    startTrackEvent(name?: string) {
        this.appInsights.startTrackEvent(name);
    }

    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }

    trackPageView(pageView?:IPageViewTelemetry) {
        this.appInsights.trackPageView(pageView);
    }

    startTrackPage(name?: string) {
        this.appInsights.startTrackPage(name);
    }

    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        this.appInsights.stopTrackPage(name, url, properties, measurements);
    }

    trackMetric(metric:IMetricTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackMetric(metric, properties);
    }

    trackException(exception: IExceptionTelemetry,  properties?: ICustomProperties) {
        this.appInsights.trackException(exception, properties);
    }

    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }

    trackDependencyData(dependency: IDependencyTelemetry) {
        this.appInsights.trackDependencyData(dependency);
    }

    flush() {
        this.appInsights.flush();
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): IPageViewTelemetry | null {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }
        return snapshot.component;
    }
}
