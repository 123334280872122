import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
		path: '',
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/ess-location/ess-location.module').then(main => main.EssLocationModule)}
		]
	},	
  { 
    path: 'login', 
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) 
  },
  { 
    path: 'message-center',
    loadChildren: () => import('./modules/message-center/message-center.module').then(m => m.MessageCenterModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./modules/ess-location/components/thank-you/thank-you.module').then(m => m.ThankYouModule) 
  },
  { 
    path: 'connect', 
    loadChildren: () => import('./modules/connect/connect.module').then(m => m.ConnectModule),
    title: 'Am I Connected?'
  },
  { 
    path: '.well-known/assetLinks.json', 
    loadChildren: () => import('./modules/well-known/well-known.module').then(m => m.WellKnownModule),
    title: 'Well Known - assetLinks.json'
  },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
