<div fxLayout="row" fxLayoutAlign="center center" class="mt-15">
  <mat-card appearance="outlined" fxFlex="90" fxFlex.gt-sm="40" class="p-0 mat-elevation-z6 box">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="bg-primary box-header"
    >
      <mat-icon class="mat-icon-xlg">error</mat-icon>
      <h1 class="error">404</h1>
    </div>
    <mat-card-content
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="box-content"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="box-text m-0" i18n>
          Opps, it seems that this page does not exist.
        </p>
        <p class="box-text m-0" i18n>If you are sure it should, search for it.</p>
        <mat-form-field appearance="outline" class="w-100 mt-1">
          <mat-label i18n>Search keyword</mat-label>
          <input
            matInput
            placeholder="Enter search keyword..."
            i18n-placeholder
            [(ngModel)]="routerLink"
          />
        </mat-form-field>
      </div>
      <div class="box-footer">
        <button
          mat-raised-button
          color="primary"
          class="mat-elevation-z8"
          type="button"
          (click)="goHome()"
        >
          <mat-icon class="m-0">home</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          class="mat-elevation-z8"
          type="button"
          (click)="searchForRoute()"
        >
          <mat-icon class="m-0">search</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
