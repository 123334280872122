import { Menu } from '../models/menu.model';

export enum MenuItemsEnum {
	PersonalData = 10,
	Communication = 20,
	PermanentResidence = 30,
	MailingAddress = 40,
	EmergencyContact = 50,
	NameChange = 60
}

export const menuItems = [
	new Menu(
		MenuItemsEnum.PersonalData,
		$localize`Personal Data`,
		'/personal-data',
		null,
		'assignment_ind',
		null,
		null,
		false,
		0
	),
	new Menu(
		MenuItemsEnum.Communication,
		$localize`Communication`,
		'/communication',
		null,
		'alternate_email',
		null,
		null,
		false,
		0
	),
	new Menu(
		MenuItemsEnum.PermanentResidence,
		$localize`Permanent Residence`,
		'/permanent-residence',
		null,
		'location_on',
		null,
		null,
		false,
		0
	),
	new Menu(
		MenuItemsEnum.MailingAddress,
		$localize`Mailing Address`,
		'/mailing-address',
		null,
		'contact_mail',
		null,
		null,
		false,
		0
	),
	new Menu(
		MenuItemsEnum.EmergencyContact,
		$localize`:@@MenuEmergencyContact:Emergency Contact`,
		'/emergency-contact',
		null,
		'contacts',
		null,
		null,
		false,
		0
	),
	// new Menu(
	// 	MenuItemsEnum.NameChange,
	// 	$localize`Name Change`,
	// 	'/name-change',
	// 	null,
	// 	'manage_accounts',
	// 	null,
	// 	null,
	// 	false,
	// 	0
	// )
];
