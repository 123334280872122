import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'not-authorized',
	templateUrl: './not-authorized.component.html',
	styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {
	public isMessageCenterPath: boolean = false;
	private messageCenterRoutePath: string = "message-center"

	constructor(
		public router: Router,
		private route: ActivatedRoute,
		private cookieService: CookieService
	) { }

	ngOnInit() {
		this.checkIfMessageCenterPath();
	}

	public goHome(): void {
		this.router.navigate(['/']);
	}

	public logout() {
		this.cookieService.delete('authResponse');
		this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
	}

	private checkIfMessageCenterPath() {
		let returnUrl = this.route?.snapshot?.queryParams['returnUrl'];
		const regex = new RegExp(this.messageCenterRoutePath);

		if (returnUrl && (regex.test(returnUrl))) {
			this.isMessageCenterPath = true;
		}
	}
}
