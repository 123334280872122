import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './shared/shared.module';
import { LoadingService } from './core/services/loading.service';
import { AppInterceptor } from './core/interceptors/app-interceptor';
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './shared/utils/custom-overlay-container';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { menuScrollStrategy } from './shared/utils/scroll-strategy';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationInsightsService } from "./telemetry.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
  ],
  providers: [
    [LoadingService, CookieService, ApplicationInsightsService],
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    AppSettings,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
